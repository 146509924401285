<template>
    <div style="background-color: #F5F5F5;
        padding-bottom: 80px;">
        <div class="zx-banner-bar" id="index">
        </div>
        <div class="zx-recruit-detail-box">
            <div class="zx-recruit-detail-top">
                <div class="zx-recruit-detail-top-left">
                    <span>发布时间： 2022-11-30</span>
                    <h3>{{detailData?.recruitName}}</h3>
                    <p>{{`${detailData.years} | 本科 | ${detailData.salaryRange}` }}<br/>{{detailData.place}}</p>
                </div>
                <div class="zx-recruit-detail-top-right">
                    <div>分享<img class="share-icon" src="../../assets/product/zx-share-icon.png" alt=""></div>
                    <a href="mailto:hr@amicloud.cn">申请职位</a>
                </div>
            </div>
            <h2 class="zx-recruit-detail-title">
                职位描述
            </h2>
            <div style="text-align: left; color: #000; font-size: 20px;font-weight: normal;line-height: 32px;">
                <h3 style="margin-top: 36px;margin-bottom: 20px;">岗位职责</h3>
                <pre style="text-indent:0em;white-space: pre-wrap;">{{`${detailData.positionDesc}`}}</pre>
                <h3 style="margin-top: 60px;margin-bottom: 20px">任职资格</h3>
                <pre style="text-indent:0em;white-space: pre-wrap;">{{`${detailData.positionQua}`}}</pre>
            </div>
            <h2 class="zx-recruit-detail-title" style="margin: 50px 0;">
                职位信息
            </h2>
            <div  style="text-align: left">
                <p style="color: #999CB0;font-size: 18px;">职位类别</p>
                <div style="color: #121212;font-size: 18px;margin-top: 10px;">{{detailData.positionType}}</div>
            </div>
            <h2 class="zx-recruit-detail-title" style="margin: 50px 0;">
                公司信息
            </h2>
            <div class="zx-recruit-detail-gongsi" style="text-align: left">
                <div class="detail-logo-box">
                    <img src="../../assets/product/recurit-logo.png" alt="">
                </div>
                <div class="zx-gonsi-detail">
                    <h3>掌讯信息技术有限公司</h3>
                    <p>厦门掌讯信息技术有限公司成立于2010年12月，公司秉持“诚信、求真、敬业、创新”核心理念，深耕于金融行业，推动聚合数据并搭建数据安全流通网络，为金融机构及金融监管提供数据要素服务及场景化应用服务。公司核心团队成员主要来自商业银行、通信运营商以及知名互联网企业等一流的专业人才，对大数据、人工智能等技术在金融行业的应用具有丰富经验和很强的创新能力。</p>
                </div>
            </div>
            <div style="width: 1130px;
height: 1px;
background: #F1F1F1;
margin: 40px 0 60px;
"></div>
            <div class="zx-recruit-detail-footer">
                <a href="mailto:hr@amicloud.cn">申请职位</a>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
        data(){
            return {
                searchKey: '',
                curNavName: '首页',
                navlist:[
                    {
                        name:'首页',
                        id: 'index'
                    },
                    {
                        name:'产品',
                        id: 'product'
                    },
                    {
                        name:'解决方案',
                        id: 'solution'
                    },
                    {
                        name:'客户案例',
                        id: 'partners'
                    },
                    {
                        name:'关于我们',
                        id: 'aboutUs'
                    }
                ],
                detailData:null
            }
        },
        created() {
            this.detailData = this.$route.query
        },
        methods:{
        }
    }
</script>

<style lang="scss" scoped>
    .zx-banner-bar {
        height: 600px;
        background: url("../../assets/product/zx-recruit-bg.png") no-repeat;
        background-size:cover ;
    }
</style>
<style lang="scss" scoped>
    .zx-recruit-detail-box{
        width: 1200px;
        background-color: #fff;
        min-height: 100vh;
        margin: 30px auto;
        padding: 30px;
    }
    .zx-recruit-detail-top{
        height: 240px;
        display: flex;
        justify-content: space-between;
    }
    .zx-recruit-detail-top-left{
        text-align: left;
        display: flex;
        flex-direction: column;
        span{

            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #999CB0;
            margin-bottom: 10px;
        }
        h3{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 159.02%;
            color: #121212;
            margin-bottom: 20px;
        }
        p{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 159.02%;
            color: #999CB0;
        }
    }
    .zx-recruit-detail-top-right{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        >div{
            display: flex;
            align-items: center;
            img{
                margin-left: 5px;
                font-size: 18px;
            }

            margin-bottom: 103px;
        }
        a{
            width: 156px;
            height: 56px;
            line-height: 56px;
            display: block;
            background: #121212;
            border-radius: 4px;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            text-decoration: none;
        }
    }

    .zx-recruit-detail-title{
        font-family: 'PingFang HK';
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 159.02%;
        color: #121212;
        position: relative;
        text-align: left;
        &:after{
            position: absolute;
            content: '';
            position: absolute;
            width: 1027px;
            height: 1px;
            background: #F1F1F1;
            top: 18px;
            left: 143px;
        }
    }

    .zx-recruit-detail-gongsi{
        display: flex;
        align-items: center;
        .detail-logo-box{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 22px;
            width: 70px;
            height: 70px;
            background: #FFFFFF;
            border: 1px solid #F1F1F1;
            border-radius: 4px;
        }
        .zx-gonsi-detail{
            width: 1044px;
            text-align: left;
            h3{

                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                line-height: 159.02%;
                color: #121212;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 159.02%;
                color: #999CB0;

            }
        }
    }

    .zx-recruit-detail-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        a{
            width: 156px;
            height: 56px;
            line-height: 56px;
            display: block;
            background: #121212;
            border-radius: 4px;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            margin-bottom: 16px;
            text-decoration: none;

        }
        p{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 159.02%;
            color: #999CB0;
        }
    }
</style>
